import React from "react";
import { Link } from "react-router-dom";


import List_c from "../Components/List";



export default function List_r() {

  function exitSystemHandler() {
    localStorage.removeItem('authentication_token');
    window.location.href = '/';
  }

  return (
    <div className="List_r_Div">

      <Link to="/create"><button className="HandleButton">ایجاد سند</button></Link>
      <input className="ListSearchInput" placeholder="جستجو"/>
      <button className="HandleButton" onClick={exitSystemHandler}>خروج از سیستم</button>

      <br />
      <br />

      <div className="CardsHolder">
        <List_c />
      </div>


      



    </div>
  );
}