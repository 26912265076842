import React from "react";
import { Link } from "react-router-dom";


export default function Create_r() {
  return (
    <div className="Create_r_Div">
      <h3>ایجاد سند جدید</h3>
      
      <p></p>
      
      <input placeholder="عنوان"/>
      <textarea rows="30" placeholder="متن سند"></textarea>
      <input placeholder="کد یکتا"/>
      {/* <input name="key" type="password" className="InputKey" placeholder="کلید (الزامی)"/> */}
      <button>ایجاد سند</button>
      <Link to="/"><button>بازگشت</button></Link>

      <br />
      <br />
      <br />
    </div>
  );
}