import React from "react";
import { Turnstile } from '@marsidev/react-turnstile';


export default function Login_r() {
  const [keyInput, keyInputSet] = React.useState('');
  const [logP, logPSet] = React.useState('');
  const [isButtonDisable, isButtonDisableSet] = React.useState(true);
  const [isInputHidden, isInputHiddenSet] = React.useState(true);
  const [isTurnstileHidden, isTurnstileHiddenSet] = React.useState(false);




  function loginHandler() {
    logPSet('');
    isButtonDisableSet(true);
    // Sending and receiving data in JSON format using POST method
    //
    var xhr = new XMLHttpRequest();
    var url = "https://00.agents.vuvexa.com/authentication/login";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
            var json = JSON.parse(xhr.responseText);

            if (json.status == '0') {
              localStorage.setItem('authentication_token', json.data.token);
              logPSet(json.message);

              window.location.href = '/';
            } else {
              logPSet(json.message);
              isButtonDisableSet(false);
            }
        } else {
          logPSet("خطایی رخ داده است.");
          isButtonDisableSet(false);
        }
    };
    var data = JSON.stringify({"key1": keyInput});
    xhr.send(data);
  }



  function keyInputHandler(e) {
    keyInputSet(e.target.value);

    if (e.target.value.length > 3) {
      isButtonDisableSet(false);
    } else {
      isButtonDisableSet(true);
    }
  }




  function successfulTurnstileHandler() {
    setTimeout(() => {
      isInputHiddenSet(false);
      isTurnstileHiddenSet(true);
    }, 1000)
  }

  function expiredTurnstileHandler() {
    isInputHiddenSet(true);
    isButtonDisableSet(true);
    keyInputSet('');
    isTurnstileHiddenSet(false);
  }

  // const ref = React.useRef()
  return (
    <div className="Login_r_Div">
      <p>{logP}</p>
      <input type="password" placeholder="کلید" onInput={keyInputHandler} value={keyInput} hidden={isInputHidden}/>
      <br />
      <br />

      <Turnstile
        // ref={ref}
        onSuccess={successfulTurnstileHandler}
        onExpire={expiredTurnstileHandler}
        style={{'margin': 'auto', backgroundColor: 'white'}} 
        options={{theme: 'light', size: 'compact', language: 'fa'}}
        siteKey="0x4AAAAAAACV6MGnxbGlhWvD"
        hidden={isTurnstileHidden} />


      <button onClick={loginHandler} style={{width: '132px'}} hidden={isButtonDisable}>ورود به سیستم</button>
    </div>
  );
}