import React from "react";



export default function List_c(title, code) {

  const [elements, elementsSet] = React.useState([<p>هیچ سندی موجود نیست.</p>]);
  const [isLoaded, isLoadedSet] = React.useState(false);

  function Card_c(title, code) {
    return (
      <div className="ListCardDiv">
        <h3>
          <span>{title}</span>
          <div><span className="DocumentCodeSpan">{code}</span></div>
          <div className="ListCardActionBoxDiv">
            <button className="ViewButton">مشاهده</button>
            <button className="ViewButton">ویرایش</button>
            <button className="ViewButton">حذف</button>
          </div>
        </h3>
      </div>
    );
  }
  
  function loadDocumentsHandler() {
    // Sending and receiving data in JSON format using POST method
    //
    var xhr = new XMLHttpRequest();
    var url = "https://00.agents.vuvexa.com/documents/list";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        var json = JSON.parse(xhr.responseText);
        isLoadedSet(true);

        if (json.status == '0') {
          if (json.data.documents == {}) {
            return 1;
          } else {
            let documents = json.data.documents;
            let keys = Object.keys(documents);
            let temp_elements = [];
            // let elements = keys.map((key) => {
            //   Card_c(documents[key].title, documents[key].)
            // })

            keys.forEach(element => {
              temp_elements.push(Card_c(title=documents[element].title, code=element))
            });


            elementsSet(temp_elements.reverse());
          }

        } else {
          return 1;
        }
      } else {
        return 1;
      }
    };
    var data = JSON.stringify({"token": localStorage.getItem("authentication_token")});
    xhr.send(data);
  }
  
  
  
  
  






  function startHandle() {
    setTimeout(() => {
      loadDocumentsHandler();
    }, 100)
  }


  if (isLoaded == false) {
    startHandle();
  }




  return (
    <div>
      {elements}
    </div>
  );

}