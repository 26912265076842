import './App.css';

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Login_r from './Routes/Login';
import Create_r from './Routes/Create';
import List_r from './Routes/List';

function App() {


  const [isLoggedIn , isLoggedInSet] = React.useState(false);
  const [isLoading, isLoadingSet] = React.useState(true);








  
  function setLoadedHandler(timeInMs) {
    setTimeout(() => {
      isLoadingSet(false);
    }, timeInMs)
  }

  function checkLoginHandler() {
    let authentication_token = localStorage.getItem("authentication_token");
    if (authentication_token == null) {
      setLoadedHandler(100);
    } else {
      // Sending and receiving data in JSON format using POST method
      //
      var xhr = new XMLHttpRequest();
      var url = "https://00.agents.vuvexa.com/authentication/check";
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.onreadystatechange = function () {
          if (xhr.readyState === 4 && xhr.status === 200) {
              var json = JSON.parse(xhr.responseText);
  
              if (json.status == '0') {
                isLoggedInSet(true);
              } else {
                setLoadedHandler(100);
              }
          } else {
            setLoadedHandler(100);
          }
      };
      var data = JSON.stringify({"token": authentication_token});
      xhr.send(data);
    }
  }



  if (isLoggedIn == false) {
    checkLoginHandler();

    if (isLoading == true) {
      return (
        <div className="App">
          <Router>
            <header className="App-header">
              <p>
                <code>سیستم شخصی آرشاویر</code>
              </p>
            </header>

            <div className='ContentArea'>
              <p>درحال بارگیری</p>
            </div>

          </Router>
        </div>
      );
    } else {
      return (
        <div className="App">
          <Router>
            <header className="App-header">
              <p>
                <code>سیستم شخصی آرشاویر</code>
              </p>
            </header>

            <div className='ContentArea'>
              <Switch>
                <Route path="/*">
                  <Login_r />
                </Route>
              </Switch>
            </div>

          </Router>
        </div>
      );
    }
  }





  return (
    <div className="App">
      <Router>
        <header className="App-header">
          <p>
            <code>سیستم شخصی آرشاویر</code>
          </p>
        </header>

        <div className='ContentArea'>
          <Switch>
            <Route path="/create" exact>
              <Create_r />
            </Route>
            <Route path="/login" exact>
              <Login_r />
            </Route>
            <Route path="/" exact>
              <List_r />
            </Route>
            <Route path="/s" exact>
            <p>مجوز دسترسی به این صفحه را ندارید.</p>
            </Route>
            <Route path="/*">
              <h3>صفحه مورد نظر پیدا نشد.</h3>
              <a href="/">بازگشت به صفحه اصلی</a>
            </Route>
          </Switch>
        </div>

      </Router>
    </div>
  );
}

export default App;
